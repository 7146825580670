import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import StyleProvider from '@yubi/yb-style-provider';
import { isENVLocal } from 'httpCommon';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './index.css';
import { store } from './Redux_Store';
import reportWebVitals from './reportWebVitals';

if (!isENVLocal()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_API_KEY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
  });
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <StyleProvider
      baseUrl={
        process.env.REACT_APP_PHOENIX_URL ||
        'https://design-system-qa.go-yubi.in/tokens'
      }>
      <App />
    </StyleProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
