import YbCoreTypography from '@yubi/yb-core-typography';
import { FlexDivHeight } from 'Features/Settlements/Settlements.styles';

const HealthCheck = () => {
  return (
    <FlexDivHeight position="center" align="center" data-testid="error-page">
      <YbCoreTypography color="#667085" size={14} align="center">
        APPLICATION IS RUNNING
      </YbCoreTypography>
    </FlexDivHeight>
  );
};

export default HealthCheck;
