import styled, { css } from 'styled-components';
import {
  PURE_WHITE,
  SECONDARY_COLOR,
  SECONDARY_PADDING,
  WHITE_COLOR,
} from './constants';
import {
  FlexDivTypes,
  GapProps,
  IDividerProps,
  IFLexDiv,
  IFooterProps,
} from './contracts';

export type ContentContainerProps = {
  position?: string;
  gap?: string;
  color?: string;
};

export const ContentContainer = styled.div<ContentContainerProps>`
  width: 100%;
  background: ${({ color }) => color || WHITE_COLOR};
  height: 100% !important;
  display: flex;
  justify-content: ${({ position }) => position || 'space-between'};
  flex-direction: column;
  // padding: 0rem 1rem;
  overflow-y: auto !important;
  gap: ${({ gap }) => gap || 0};
  flex: 1;
`;

export const StyledDivider = styled.div<IDividerProps>`
  height: 1px;
  background-color: ${(props) => props.color || SECONDARY_COLOR};
  width: 100%;
`;

export const StyledFlexDiv = styled.div<IFLexDiv>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  align-items: ${(props) => props.alignItems || 'center'};
  gap: ${(props) => props.gap || '0px'};
  padding: ${(props) => props.padding || SECONDARY_PADDING};
  width: ${(props) => props.width};
  flex: ${(props) => props.flex};
  flex-wrap: wrap;
  margin: ${(props) => props.margin || 0};
`;

export const FooterDiv = styled.footer<IFooterProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  width: 100%;
  background: ${({ bgColor }) => bgColor || '#f9fafc'};
  padding: 0.4rem 1rem 1.5rem 1rem;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  bottom: 0;
  left: -0.1rem;
  @media (min-width: 767px) {
    position: fixed;
    bottom: 0;
    left: ${(props) => props.bodyLeft};
    width: ${(props) => props.bodyWidth};
  }
`;

export const StyledImage = styled.img`
  // border-radius: 8px;
`;

const getFlexStyle = ({
  direction,
  position,
  padding,
  gap,
  align,
  width,
  margin,
  height,
}: FlexDivTypes) => {
  return css`
    width: ${width || '100%'};
    height: ${height};
    flex-direction: ${direction || 'column'};
    justify-content: ${position || 'center'};
    padding: ${padding || '0'};
    gap: ${gap || '0'};
    align-items: ${align || 'inherit'};
    margin: ${margin};
  `;
};
export const FlexDiv = styled.div<FlexDivTypes>`
  display: flex;
  word-break: break-word;
  min-width: fit-content;
  ${getFlexStyle}
`;

export const TextWrapper = styled.div`
  padding-bottom: 16px;
`;

const gapStyles = ({ bgColor }: GapProps) => {
  if (bgColor?.toUpperCase() === PURE_WHITE) {
    return css`
      background-color: #ffffff;
      width: auto;
      margin: 0 -1rem;
      overflow-x: hidden;
    `;
  }
};
export const Gap = styled.div<GapProps>`
  height: ${(prop) => prop.height || '5rem'};
  width: 100%;
  background-color: ${(props) => props.bgColor};
  ${gapStyles}
`;

export const AnchorTag = styled.a`
  &:link {
    color: #667085;
    background-color: transparent;
    text-decoration: none;
  }
`;
