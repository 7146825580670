import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config?: any) => {
    const borrowerHash = localStorage.getItem('customerHashCode');
    
    config.headers = {
      ...config.headers,
      'Content-type': 'application/json',
      'Content-Security-Policy': "default-src 'self'",
      'Strict-Transport-Security':
        'max-age=31536000; includeSubDomains; preload',
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'DENY',
      'Referrer-Policy': 'strict-origin-when-cross-origin',
      borrowerHash: borrowerHash,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
