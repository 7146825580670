import { FlexDiv } from 'Shared/elements';
import styled from 'styled-components';

export const FlexDivHeight = styled(FlexDiv)`
  height: 100%;
  flex: 1;
`;

export type RecommendedProps = {
  left: number;
};
export const Recommended = styled.div<RecommendedProps>`
  position: fixed;
  bottom: 4.6rem;
  background: #dbf3d5;
  padding: 0.1rem 0.6rem;
  border-radius: 4px;
  right: 3.5rem;

  @media (min-width: 767px) {
    position: fixed;
    bottom: 4.6rem;
    right: ${({ left }) => left && `${left}px`};
  }
`;
