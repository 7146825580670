//Images
export const CREDIT_CARD = '/assets/enbd-card.jpeg';

//CSS
//Colors
export const PRIMARY_COLOR = '#45108A';
export const SECONDARY_COLOR = '#E5EBF4';
export const DISABLED_BUTTON_BACKGROUND = '#E6E9F5';
export const WHITE_COLOR = '#F9FAFC';
export const PURE_WHITE = '#FFFFFF';
export const LIGHT_WHITE = '#F8FAFC';
export const PRIMARY_BUTTON_BG = '#7546CB';
export const DISABLED_TEXT_COLOR = '#9AA5B8';
export const HIGHLIGHTED_TEXT_COLOR = '#464C62';
export const TEXT_COLOR = '#65686e';
export const SUCCESS_GREEN = '#14AE5C';
export const USER_LINK_COLOR = '#21356A';
export const FEEDBACK_TEXT_COLOR = '#4A5567';

//Text Styling
export const DEFAULT_FONT_WEIGHT = 400;
export const HIGHLIGHTED_FONT_WEIGHT = 800;

export const TITLE_FONT_SIZE = '18px';
export const TITLE_LINE_HEIGHT = '27px';

export const TEXT_FONT_SIZE = '14px';
export const TEXT_LINE_HEIGHT = '20px';

export const SECONDARY_TITLE_FONT_SIZE = '16px';
export const SECONDARY_TITLE_LINE_HEIGHT = '24px';

export const CUSTOMER_INFO_FONT_SIZE = '12px';
export const CUSTOMER_INFO_LINE_HEIGHT = '18px';

export const DECLARATION_LETTER_FONT_SIZE = '12px';
export const DECLARATION_LETTER_LINE_HEIGHT = '20px';

export const USER_INFO_TITLE_FONT_SIZE = '24px';
export const USER_INFO_TITLE_LINE_HEIGHT = '36px';

//Padding
export const PRIMARY_PADDING = '16px';
export const SECONDARY_PADDING = '8px';

//Border Radius
export const PRIMARY_BORDER_RADIUS = '16px';
export const SECONDARY_BORDER_RADIUS = '8px';

//Icon size
export const DEFAULT_ICON_SIZE = '24px';
export const LARGE_ICON_SIZE = '54px';

export const authEnabled = {
  '835': true,
  '125': true,
};
