import { AppStore, RemediationTypes } from './contracts';

export const selectClientId = ({ borrowerWorkflow }: AppStore) =>
  borrowerWorkflow.workflowDetails.client.clientId;

export const remediationTypeSelector = ({
  borrowerWorkflow,
}: AppStore): RemediationTypes =>
  borrowerWorkflow.workflowDetails.remediationType;

export const selectBorrowerEmail = ({ borrowerWorkflow }: AppStore) =>
  borrowerWorkflow.workflowDetails.borrowerData.borrowerEmail;
