import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  UpdateUserFeedback,
  UpdateUserSelectionOption,
  WorkFlowDetails,
} from 'Redux_Store/contracts';
import axiosInstance from 'Shared/axiosInstance';
import { getEnvironmentBaseUrl } from 'httpCommon';
import { notify } from './helpers';

export const updateUserSelectionOption = createAsyncThunk(
  'posts/updateUserSelectionOption',
  async (
    { callBackFunction, ...userSelectionOption }: UpdateUserSelectionOption,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `${getEnvironmentBaseUrl()}/v1/allocations/disposition`,
        userSelectionOption
      );

      if (response.status === 201 || response.status === 200) {
        callBackFunction();

        return response.data;
      }

      notify();
      return rejectWithValue('error');
    } catch (e) {
      notify();
      return rejectWithValue(e);
    }
  }
);

export const getWorkflowDetails = createAsyncThunk(
  'posts/getWorkflowDetails',
  async (customerHashCode: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getEnvironmentBaseUrl()}/v1/allocations/${customerHashCode}`
      );

      if (response.status === 200) {
        const res = response.data as WorkFlowDetails;

        return {
          workflowDetails: {
            ...res,
            borrowerData: {
              ...res.borrowerData,
              phoneNumber: '+91-XXXXXXX1778',
            },
          },
        };
      }

      return rejectWithValue('error');
    } catch (error: unknown) {
      return rejectWithValue('error');
    }
  }
);

export const updateUserFeedback = createAsyncThunk(
  'posts/updateUserFeedback',
  async (params: UpdateUserFeedback, { rejectWithValue }) => {
    const { callbackFunction, ...borrowerJourney } = params;

    try {
      const response = await axiosInstance.post(
        `${getEnvironmentBaseUrl()}/v1/allocations/borrowerCsat`,
        borrowerJourney
      );

      if (response.status === 201) {
        return callbackFunction();
      } else {
        notify();
        return rejectWithValue('error');
      }
    } catch (e) {
      notify();
      return rejectWithValue('error');
    }
  }
);
