const SadIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 177 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_78_164)">
        <path
          d="M176.29 88.11C176.32 136.56 136.7 176.28 88.32 176.31C39.66 176.34 0 136.75 0 88.11C0 39.61 39.67 0 88.24 0C136.66 0 176.27 39.64 176.29 88.11ZM88.17 165.26C130.65 165.25 165.23 130.67 165.24 88.17C165.25 45.68 130.7 11.08 88.21 11.05C45.65 11.02 11.01 45.66 11.05 88.22C11.09 130.71 45.69 165.27 88.17 165.26Z"
          fill="#EC1C24"
        />
        <path
          d="M86.7698 93.6C105.77 93.94 119.66 101.14 129.71 115.57C130.32 116.45 130.88 117.38 131.39 118.32C132.93 121.2 132.09 124.43 129.45 125.96C126.71 127.54 123.52 126.59 121.78 123.66C116.89 115.42 109.97 109.59 100.84 106.67C83.6898 101.17 64.3798 106.87 54.5198 123.64C52.7998 126.56 49.5698 127.52 46.8498 125.95C44.1498 124.39 43.2698 121.1 45.0098 118.18C52.5698 105.49 63.5598 97.51 78.0898 94.65C81.4098 94 84.8298 93.83 86.7698 93.61V93.6Z"
          fill="#EC1C24"
        />
        <path
          d="M60.5999 77.1C54.5399 77.1 49.6699 72.27 49.6099 66.2C49.5499 60.09 54.4899 55.14 60.6199 55.14C66.6499 55.14 71.5899 60.03 71.6299 66.04C71.6699 72.15 66.7399 77.09 60.5999 77.1Z"
          fill="#EC1C24"
        />
        <path
          d="M115.57 77.1C109.53 77.05 104.65 72.12 104.67 66.1C104.68 60.01 109.67 55.1 115.8 55.14C121.84 55.18 126.71 60.1 126.69 66.15C126.67 72.28 121.72 77.16 115.57 77.1Z"
          fill="#EC1C24"
        />
      </g>
      <defs>
        <clipPath id="clip0_78_164">
          <rect width="176.29" height="176.31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SadIcon;
