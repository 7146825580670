import YbCoreSpinner from '@yubi/yb-core-spinner';
import { useStyles } from '@yubi/yb-style-provider';
import React from 'react';
import { FlexDiv } from 'Shared/elements';
import styled from 'styled-components';

const Loader: React.FC = () => {
  const { styleConnector } = useStyles();
  return (
    <FlexDivHeight data-testid="spinner" position="center" align="center">
      <YbCoreSpinner
        size="small"
        durationInMills={1000}
        styleConnector={styleConnector}
      />
    </FlexDivHeight>
  );
};

const FlexDivHeight = styled(FlexDiv)`
  height: 100vh;
`;

export default Loader;
