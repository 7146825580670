import { useEffect, useState } from 'react';

export const useBodyPosition = () => {
  const [bodyPosition, setBodyPosition] = useState({
    top: 0,
    left: 0,
    right: 0,
    bodyWidth: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      const body = document.body;
      const bodyWidth = body.offsetWidth;
      const { top, left, right } = body.getBoundingClientRect();
      setBodyPosition({ top, left, bodyWidth, right });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return bodyPosition;
};
