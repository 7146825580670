import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BorrowerWorkflow,
  RemediationTypes,
  UpdateUserSelectionOptionResponse,
  WorkFlowDetails,
} from 'Redux_Store/contracts';
import {
  getWorkflowDetails,
  updateUserFeedback,
  updateUserSelectionOption,
} from 'Redux_Store/thunks';
import { UpdateInstallment } from './contracts';

export const workFlowDetails: WorkFlowDetails = {
  batchNumber: '',
  allocationId: '',
  jobId: '',
  referenceNumber: '',
  remediationType: null as unknown as RemediationTypes,
  client: {
    currency: '',
    clientName: '',
    clientId: '',
  },
  borrowerData: {
    displayName: '',
    borrowerEmail: '',
    borrowerMobileNo: '',
    loanAccountNo: '',
    totalOutStanding: '',
    borrowerMaskedPhoneNumber: '',
  },
  remediationDetails: {
    installmentOptions: [
      {
        id: 0,
        installmentAmount: '',
        tenure: '',
        rateOfInterest: '',
        waiver: '',
        totalPayableAmount: '',
        lastEmiValue: '',
      },
    ],
  },
  yobarray: [],
  linkExpiryStatus: false,
};

export const initialState: BorrowerWorkflow = {
  workflowDetails: workFlowDetails,
  offerSelected: undefined,
  isLoading: false,
  isError: false,
  referenceNumberSubmitted: '',
  termsAndConditionsSelected: false,
};

export const slice = createSlice({
  name: 'borrowerWorkflow',
  initialState,
  reducers: {
    updateInstallment: (state, action: PayloadAction<UpdateInstallment>) => {
      state.offerSelected = action.payload.selectedInstallment;
    },
    updateTermsAndConditions: (state, action: PayloadAction<boolean>) => {
      state.termsAndConditionsSelected = action.payload;
    },
    updateLinkExpiry: (state, action: PayloadAction<boolean>) => {
      state.workflowDetails.linkExpiryStatus = action.payload;
    },
    resetTermsAndExpiry: (state) => {
      state.termsAndConditionsSelected = false;
      //Un comment if needed to reset link expiry status
      // state.workflowDetails.linkExpiryStatus = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getWorkflowDetails.fulfilled, (state, { payload }) => {
      state.workflowDetails = payload.workflowDetails;
      state.isLoading = false;
    });

    builder.addCase(getWorkflowDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getWorkflowDetails.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });

    builder.addCase(
      updateUserSelectionOption.fulfilled,
      (state, action: PayloadAction<UpdateUserSelectionOptionResponse>) => {
        state.isLoading = false;
        state.referenceNumberSubmitted = action.payload.referenceNumber;
      }
    );
    builder.addCase(updateUserSelectionOption.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserSelectionOption.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateUserFeedback.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateUserFeedback.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateUserFeedback.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const { actions, reducer } = slice;
export const {
  updateInstallment,
  updateTermsAndConditions,
  updateLinkExpiry,
  resetTermsAndExpiry,
} = actions;
export default reducer;
