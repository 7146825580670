import YbCoreTypography from '@yubi/yb-core-typography';
import { useClientContacts } from 'hooks/client/useClientContacts';

import { AnchorTag, FlexDiv } from 'Shared/elements';
import styled from 'styled-components';

const LenderContacts = () => {
  const client = useClientContacts();
  if (!client) return null;
  const { email, phoneNumber } = client;

  return (
    <FlexDivMargin align="flex-start" gap="16px">
      <YbCoreTypography
        fontFamily="Regular"
        weight="Regular"
        size={16}
        lineHeight={24}
        color="#101828"
      >
        Need help?
      </YbCoreTypography>
      <FlexDivWrap
        width="100%"
        direction="row"
        position="space-between"
        align="flex-start"
        gap="8px"
      >
        <FlexDiv width="none" direction="row" align="center" gap="8.5px">
          <img src="/assets/common/icons/mail.svg" alt="mail" />
          <div style={{ wordBreak: 'break-all' }}>
            <YbCoreTypography weight="800" size={12} lineHeight={18}>
              <AnchorTag href={`mailto:${email}`}>Mail us</AnchorTag>
            </YbCoreTypography>
          </div>
        </FlexDiv>
        <FlexDiv width="none" direction="row" align="center" gap="8.5px">
          <img src="/assets/common/icons/phone-call.svg" alt="phone-call" />
          <YbCoreTypography weight="800" size={12}>
            <AnchorTag href={`tel:${phoneNumber}`}>Call us</AnchorTag>
          </YbCoreTypography>
        </FlexDiv>
      </FlexDivWrap>
    </FlexDivMargin>
  );
};

const FlexDivMargin = styled(FlexDiv)`
  padding: 16px;
`;

const FlexDivWrap = styled(FlexDiv)`
  flex-wrap: wrap;
`;

export default LenderContacts;
