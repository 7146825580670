export enum ClientName {
  ENBD = 'enbd',
  EI = 'eii',
  KO = 'kotak'
}
export enum ClientId {
  ENBD = '2525',
  EI = '836',
  KO = '125'
}


export interface Client {
  id: ClientId;
  name: ClientName;
  phoneNumber: string;
  email: string;
  website: string;
}

const enbd: Client = {
  id: ClientId.ENBD,
  name: ClientName.ENBD,
  phoneNumber: '+971 (600) 540040',
  email: 'financial.remediation@emiratesnbd.com',
  website: 'https://beta.emiratesnbd.com/en/help-and-support/debt-assist',
};

const ei: Client = {
  id: ClientId.EI,
  name: ClientName.EI,
  phoneNumber: '+971 (600) 542520',
  email: 'financial.remediation-helpdesk@emiratesislamic.ae',
  website: 'https://www.emiratesislamic.ae/eng/key-information/debt-assist/',
};

const kotak: Client = {
  id: ClientId.KO,
  name: ClientName.KO,
  phoneNumber: '',
  email: '',
  website: '',
};

export const Clients = new Map<number | string, Client>([
  [ClientId.ENBD, enbd],
  [ClientId.EI, ei],
  [ClientId.KO, kotak]
]);

export const clientMap = {
  [ClientId.ENBD]: ClientName.ENBD,
  [ClientId.EI]: ClientName.EI,
  [ClientId.KO]: ClientName.KO,
};
