import { combineReducers, configureStore } from '@reduxjs/toolkit';
import reducer from 'Redux_Store/redux.reducer';

const rootReducer = combineReducers({
  borrowerWorkflow: reducer,
});

export const reduxStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  return store;
};

export type AppDispatch = typeof store.dispatch;
export const store = reduxStore();
